import React from 'react'
import { Script } from 'gatsby'

import { Navbar, WelcomeHero, Welcome, BuyNFT, Faq, Footer } from '../views'

export default function IndexPage(){

  return (
    <main>
      <title>Home Page</title>
      <Script id="analytics" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v18.0" nonce="ydL6BBcW" />
      <Navbar />
      <div className="main">
        <WelcomeHero />
        <Welcome />
        <BuyNFT />
        <div className="container spacing">
          <hr />
        </div>
        <Faq />
      </div>
      <Footer />
    </main>
  )
}
